import axiosClient from './axiosClient';

const authApi = {
    signUpStep1: (data) => {
        const baseURL = 'user/signup/step1';
        return axiosClient.post(baseURL, {
            email: data.email,
        });
    },
    signUpStep2: (data) => {
        const baseURL = 'user/signup/step2';
        return axiosClient.post(baseURL, {
            email: data.email,
            OTPCode: data.OTPCode,
        });
    },
    signUpStep3: (data) => {
        const baseURL = 'user/signup/step3';
        return axiosClient.post(baseURL, {
            email: data.email,
            password: data.password,
            oneTimeToken: data.oneTimeToken,
        });
    },
    login: (data) => {
        const baseURL = 'user/login';
        return axiosClient.post(baseURL, {
            email: data.email,
            password: data.password,
        });
    },
    getUserInfo() {
        const baseURL = 'user/get-me';
        return axiosClient.get(baseURL);
    },
    updateMe(data) {
        const baseURL = 'user/update-me';
        return axiosClient.post(baseURL, data);
    },
    getOTP() {
        const baseURL = 'user/otp';
        return axiosClient.get(baseURL);
    },
    verifyOTP(data) {
        const baseURL = 'user/otp';
        return axiosClient.post(baseURL, {
            OTPCode: data.OTPCode,
        });
    },
    setOtpNewEmail(data) {
        const baseURL = 'user/send-otp-new-email';
        return axiosClient.post(baseURL, {
            oneTimeToken: data.oneTimeToken,
            newEmail: data.newEmail,
        });
    },
    changeEmail: (data) => {
        const baseURL = 'user/change-email';
        return axiosClient.post(baseURL, {
            newEmail: data.newEmail,
            OTPCode: data.OTPCode,
            isMiddleware: true,
        });
    },
    changePassword: (data) => {
        const baseURL = 'user/change-password';
        return axiosClient.post(baseURL, {
            newPassword: data.newPassword,
            oneTimeToken: data.oneTimeToken,
        });
    },
    changeBankInfo: (data) => {
        const baseURL = 'user/change-bank-info';
        return axiosClient.post(baseURL, {
            bankInfo: data.bankInfo,
            oneTimeToken: data.oneTimeToken,
        });
    },
    forgotPassword: (data) => {
        const baseURL = 'user/forgot-password';
        return axiosClient.post(baseURL, {
            email: data.email,
        });
    },
    verifyForgotPassword: (data) => {
        const baseURL = 'user/verify-reset-password';
        return axiosClient.post(baseURL, {
            email: data.email,
            OTPCode: data.OTPCode,
        });
    },
    resetPassword: (data) => {
        const baseURL = 'user/reset-password';
        return axiosClient.post(baseURL, {
            newPassword: data.newPassword,
            oneTimeToken: data.oneTimeToken,
        });
    },
    myOrders: (queryString) => {
        const baseURL = `user/my-orders?${queryString || ''}`;
        return axiosClient.get(baseURL);
    },
    myPendingCash: () => {
        const baseURL = `user/my-pending-cash`;
        return axiosClient.get(baseURL);
    },
    getAllUser(queryString) {
        const baseURL = `user/all_user?${queryString || ''}`;
        return axiosClient.get(baseURL);
    },
    topCommission(queryString) {
        const baseURL = `user/top_commission?${queryString || ''}`;
        return axiosClient.get(baseURL);
    },
    topPendingCash(queryString) {
        const baseURL = `user/top_pending_cash?${queryString || ''}`;
        return axiosClient.get(baseURL);
    },
    topWithDraw(queryString) {
        const baseURL = `user/top_withdraw?${queryString || ''}`;
        return axiosClient.get(baseURL);
    },
    getAllOrder(queryString) {
        const baseURL = `user/all_order?${queryString || ''}`;
        return axiosClient.get(baseURL);
    },
    getCampaign() {
        const baseURL = `campaign/get_campaigns`;
        return axiosClient.get(baseURL);
    },
    updateCampaign(data) {
        const baseURL = `campaign`;
        return axiosClient.post(baseURL, data);
    },
    orderAnalytic(queryString) {
        const baseURL = `user/order_analytic?conversionStatus=2&${
            queryString || ''
        }`;
        return axiosClient.get(baseURL);
    },
    paymentAnalytic(queryString) {
        const baseURL = `user/payment_analytic?status=2&${queryString || ''}`;
        return axiosClient.get(baseURL);
    },
    setOrderComplete(orderId) {
        const baseURL = `user/set_complete_order`;
        return axiosClient.post(baseURL, {
            orderId,
        });
    },
    bonusCash(data) {
        const baseURL = `bonus/bonus_cash`;
        return axiosClient.post(baseURL, data);
    },
};

export default authApi;
